import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            ivanovo
          }
        }
      }
    `
  )

  const metaDescription = "Проститутки Иваново расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Иваново готовы ко встрече в приватной обстановке." || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <link rel="canonical" href="https://vipdosug.org/prostitutki-ivanovo/"/>
      <link rel="alternate" href="https://vipdosug.org/prostitutki-ivanovo/" hreflang="ru"/>
      <link rel="amphtml" href="https://vipdosug.org/prostitutki-ivanovo/amp/"/>
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>
	    <link rel="icon" href="/favicon.svg" type="image/svg+xml"/>
	    <link rel="icon" href="/favicon.ico" sizes="32x32"/>
      <title>{defaultTitle ? `${title} - ${defaultTitle}` : title}</title>
      <meta property="og:locale" content="ru_RU"/>
      <meta property="og:site_name" content="vipdosug.org"/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:image" content={site.siteMetadata.ivanovo}/>
      <meta property="og:url" content="https://vipdosug.org/prostitutki-ivanovo/"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:image" content={site.siteMetadata.ivanovo}/>
      <meta name="twitter:description" content={metaDescription}/>
      <meta name="twitter:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:site" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta itemprop="image" content={site.siteMetadata.ivanovo}/>
	    <meta itemprop="description" content={metaDescription}/>
	    <meta itemprop="name" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
	    <meta name="description" content={metaDescription}/>
      <link rel="alternate" type="application/atom+xml" href="https://vipdosug.org/vipdosug.atom" title="Проститутки России, лучшие индивидуалки | VipDosug.org"/>
      <script type="application/ld+json">
  {`
  {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "url": "https://vipdosug.org/",
    "name": "VipDosug.Org",
    "sameAs": [
        "https://www.youtube.com/@dosugcz8671",
        "https://flipboard.com/@dosug",
        "https://vk.com/dosug.cz_ru",
        "https://t.me/DosugOfficialle",
        "https://twitter.com/DosugczOfficial",
        "https://www.facebook.com/dosugru.net",
        "https://ru.pinterest.com/dosugru/"
    ]
}
  `}
</script>
<script type="application/ld+json">
  {`
  {
    "@context": "https://schema.org",
    "author": {
        "@type": "WebPage",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/"
    },
    "@type": "Article",
    "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://vipdosug.org/prostitutki-ivanovo/"
    },
    "headline": "Проститутки Иваново, лучшие индивидуалки | VipDosug.org",
    "description": "Проститутки Иваново расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Иваново готовы ко встрече в приватной обстановке.",
    "articleBody": "Иваново - это не только город прекрасных невест, это еще и город профессиональных проституток. Словом, это какой-то специфический город, которому самой судьбой было назначено быть раем для мужчин. Хочешь тебе - невеста, а не хочешь - пожалуйста, проститутка. И главное, тут есть из кого выбрать, потому что девушки в этом городе не зря славятся своим изяществом, обаянием и красотой. Проституки Иваново могут все - романтический нежный классический вагинальный секс, страстный и бурный анальный секс, потрясающий оральный. Уже почувствовали легкий завод, подождите, наберите сначала номер.... и вот тогда вы сможете выпустить свои желания! Потому что с проститутками из Иваново вы можете позволить себе быть собой, потому что это просто одна ночь без всяких обязательств. Притом вы даже можете сообщить оператору о том, что хотите блондинку или шатенку, может брюнетку или рыжую. К вам приедет именно та проститутка, которая поднимет вас на вершины блаженства! Хватит сомневаться, иногда в этой жизни нужно уметь расслабиться! Индивидуалки Иваново уже ждут Вас!",
    "image": [
        "https://vipdosug.org/images/og-ivanovo.png"
    ],
   
    "articleSection": "Развлечения",
    "publisher": {
        "@type": "Organization",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/",
        "logo": {
            "@type": "ImageObject",
            "url": "https://vipdosug.org/images/amp.png",
            "width": 264,
            "height": 59
        }
    }
}
  `}
</script>
      {children}
    </>
  )
}

export default Seo
